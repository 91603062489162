<template>
  <b-card
    :class="vertical ? '' : 'mb-1'"
    :style="
      'background-color:rgba(' +
        ($store.state.appConfig.layout.skin === 'dark'
          ? '41,48,68'
          : '255,255,255') +
        ',' +
        backgroundOpacity || 1 + ')'
    "
  >
    <b-card-title v-if="title" class="font-title mb-1">
      <feather-icon class="mr-1" icon="TriangleIcon" size="18" />
      {{ title }}
    </b-card-title>
    <div class="pt-1" style="height: calc(100% - 5rem)">
      <div class="d-flex" :class="vertical ? 'h-50' : 'h-100'">
        <template v-if="vertical">
          <div style="width: 20%" class="h-100">&nbsp;</div>
        </template>
        <div
          :id="'real_time_air_quality_chart_1_' + nanoid"
          :style="
            'width: ' +
            (vertical ? '30%' : '20%') +
            ';top:' +
            (vertical ? '0' : '0') +
            ';left:' +
            (vertical ? '0' : '1.8rem')
          "
          class="h-100 position-relative"
        ></div>

        <div
          :id="'real_time_air_quality_chart_2_' + nanoid"
          :style="
            'width: ' +
            (vertical ? '30%' : '20%') +
            ';top:' +
            (vertical ? '0' : '-2rem') +
            ';left:' +
            (vertical ? '0' : '3.5rem')
          "
          class="h-100 position-relative"
        ></div>

        <template v-if="!vertical">
          <div
            :id="'real_time_air_quality_chart_4_' + nanoid"
            class="h-100 position-relative"
            style="width: 20%; top: 1rem; left: 5.3rem"
          ></div>
          <div
            :id="'real_time_air_quality_chart_3_' + nanoid"
            class="h-100 position-relative"
            style="width: 20%; top: -1.5rem; left: 2.6rem"
          ></div>
          <div
            :id="'real_time_air_quality_chart_5_' + nanoid"
            class="h-100 position-relative"
            style="width: 20%; top: 0; left: -3.5rem"
          ></div>
        </template>
        <template v-else>
          <div style="width: 20%" class="h-50">&nbsp;</div>
        </template>
      </div>
      <template v-if="vertical">
        <div class="d-flex position-relative w-100 h-50">
          <div
            :id="'real_time_air_quality_chart_4_' + nanoid"
            class="h-100 position-relative"
            style="width: 33%"
          ></div>
          <div
            :id="'real_time_air_quality_chart_3_' + nanoid"
            class="h-100 position-relative"
            style="width: 33%"
          ></div>
          <div
            :id="'real_time_air_quality_chart_5_' + nanoid"
            :style="'width:' + (vertical ? '33%' : '22%')"
            class="h-100 position-relative"
            style="width: 33%"
          ></div>
        </div>
      </template>
    </div>
    <div class="pt-1" style="height: 2rem">
      <div class="text-center">
        <span class="mr-3"
          >室外温度:
          <span class="text-success font-digit h4">{{ params.TEMP }}</span>
          °C</span
        >
        <span class="mr-3"
          >室外湿度:
          <span class="text-success font-digit h4">{{ params.HUMI }}</span>
          %</span
        >
        <span
          >室外PM2.5:
          <span class="text-success font-digit h4">{{ params.PM25 }}</span>
          μg/m3</span
        >
      </div>
    </div>
  </b-card>
</template>

<script>
import { RingProgress } from "@antv/g2plot";
import {
  onMounted,
  onUnmounted,
  reactive,
  ref,
  toRefs,
  watch,
} from "@vue/composition-api";
import { getRealTimeParams } from "@/api/hjj2";
import { routerParams } from "@/libs/utils/routerParams";
import { new_nanoid } from "@/libs/utils/nanoId";
import store from "@/store";

export default {
  name: "RealTimeAirQuality",
  props: ["title", "vertical", "background-opacity"],
  setup() {
    const project_id = routerParams("project_id");
    const nanoid = new_nanoid();
    const interval = ref(null);
    const event = reactive({
      chart_1: null,
      chart_2: null,
      chart_3: null,
      chart_4: null,
      chart_5: null,
      params: {
        TEMP: 0,
        HUMI: 0,
        PM25: 0,
        realCO2: 0,
        realHumidity: 0,
        realPM25: 0,
        realTemperature: 0,
        realVOC: 0,
      },
    });
    //b8c2cc
    const fontColor = ref("#6e6b7b");
    const chart_init_all = () => {
      chart_init_1();
      chart_init_2();
      chart_init_3();
      chart_init_4();
      chart_init_5();
    };

    const chart_init_1 = () => {
      const value = event.params.realTemperature;
      if (event.chart_1) {
        event.chart_1.destroy();
        event.chart_1 = null;
      }
      const ringProgress = new RingProgress(
        "real_time_air_quality_chart_1_" + nanoid,
        {
          autoFit: true,
          animation: false,
          percent: value / 100,
          color: ["#4169E1", "rgba(22,29,49,0.75)"],
          statistic: {
            title: {
              style: {
                color: "#4169E1",
                fontSize: "1.5rem",
                lineHeight: "2rem",
              },
              formatter: () => "温度",
            },
            content: {
              customHtml: (container, view, datum, data) => {
                const { width } = container.getBoundingClientRect();
                const text = value + " °C";
                const textStyleStr = `width:${width}px;font-size:20px;font-weight:400;color:${fontColor.value};line-height:20px`;
                return `<div><div style="${textStyleStr};">${text}</div><div style="color:green;font-size:1.6rem;font-weight:600 ;line-height:2.2rem">优</div></div>`;
              },
            },
          },
        }
      );
      ringProgress.render();
      event.chart_1 = ringProgress;
    };
    const chart_init_2 = () => {
      const value = event.params.realHumidity;
      if (event.chart_2) {
        event.chart_2.destroy();
        event.chart_2 = null;
      }
      const ringProgress = new RingProgress(
        "real_time_air_quality_chart_2_" + nanoid,
        {
          autoFit: true,
          animation: false,
          percent: value / 100,
          color: ["#00FF00", "rgba(22,29,49,0.75)"],
          statistic: {
            title: {
              style: {
                color: "#00FF00",
                fontSize: "1.5rem",
                lineHeight: "2rem",
              },
              formatter: () => "湿度",
            },
            content: {
              customHtml: (container, view, datum, data) => {
                const { width } = container.getBoundingClientRect();
                const text = value + " %";
                const textStyleStr = `width:${width}px;font-size:20px;font-weight:400;color:${fontColor.value};line-height:20px`;
                return `<div><div style="${textStyleStr};">${text}</div><div style="color:green;font-size:1.6rem;font-weight:600 ;line-height:2.2rem">优</div></div>`;
              },
            },
          },
        }
      );

      ringProgress.render();
      event.chart_2 = ringProgress;
    };
    const chart_init_3 = () => {
      const value = event.params.realCO2;
      if (event.chart_3) {
        event.chart_3.destroy();
        event.chart_3 = null;
      }
      const ringProgress = new RingProgress(
        "real_time_air_quality_chart_3_" + nanoid,
        {
          autoFit: true,
          animation: false,
          percent: value / 1000,
          color: ["#61DDAA", "rgba(22,29,49,0.75)"],
          statistic: {
            title: {
              style: {
                color: "#61DDAA",
                fontSize: "1.5rem",
                lineHeight: "2rem",
              },
              formatter: () => "CO2",
            },
            content: {
              customHtml: (container, view, datum, data) => {
                const { width } = container.getBoundingClientRect();
                const text = value + " ppm";
                const textStyleStr = `width:${width}px;font-size:20px;font-weight:400;color:${fontColor.value};line-height:20px`;
                return `<div><div style="${textStyleStr};">${text}</div><div style="color:green;font-size:1.6rem;font-weight:600 ;line-height:2.2rem">优</div></div>`;
              },
            },
          },
        }
      );

      ringProgress.render();
      event.chart_3 = ringProgress;
    };
    const chart_init_4 = () => {
      const value = event.params.realPM25;
      if (event.chart_4) {
        event.chart_4.destroy();
        event.chart_4 = null;
      }
      const ringProgress = new RingProgress(
        "real_time_air_quality_chart_4_" + nanoid,
        {
          autoFit: true,
          animation: false,
          percent: value / 100,
          color: ["#FFD700", "rgba(22,29,49,0.75)"],
          statistic: {
            title: {
              style: {
                color: "#FFD700",
                fontSize: "1.5rem",
                lineHeight: "2rem",
              },
              formatter: () => "PM2.5",
            },
            content: {
              customHtml: (container, view, datum, data) => {
                const { width } = container.getBoundingClientRect();
                const text = value + " μg/m3";
                const textStyleStr = `width:${width}px;font-size:20px;font-weight:400;color:${fontColor.value};line-height:20px;`;
                return `<div><div style="${textStyleStr};">${text}</div><div style="color:green;font-size:1.6rem;font-weight:600 ;line-height:2.2rem">优</div></div>`;
              },
            },
          },
        }
      );

      ringProgress.render();
      event.chart_4 = ringProgress;
    };
    const chart_init_5 = () => {
      const value = event.params.realVOC;
      if (event.chart_5) {
        event.chart_5.destroy();
        event.chart_5 = null;
      }
      const ringProgress = new RingProgress(
        "real_time_air_quality_chart_5_" + nanoid,
        {
          autoFit: true,
          animation: false,
          percent: value / 1000,
          color: ["#90EE90", "rgba(22,29,49,0.75)"],
          statistic: {
            title: {
              style: {
                color: "#90EE90",
                fontSize: "1.5rem",
                lineHeight: "2rem",
              },
              formatter: () => "VOC",
            },
            content: {
              customHtml: (container, view, datum, data) => {
                const { width } = container.getBoundingClientRect();
                const text = value + " ppb";
                const textStyleStr = `width:${width}px;font-size:20px;font-weight:400;color:${fontColor.value};line-height:20px`;
                return `<div><div style="${textStyleStr};">${text}</div><div style="color:green;font-size:1.6rem;font-weight:600 ;line-height:2.2rem">优</div></div>`;
              },
            },
          },
        }
      );

      ringProgress.render();
      event.chart_5 = ringProgress;
    };

    watch(
      () => store.state.appConfig.layout.skin,
      (skin) => {
        if (skin === "dark") {
          fontColor.value = "#b8c2cc";
        } else {
          fontColor.value = "#6e6b7b";
        }
        setTimeout(() => {
          chart_init_all();
        }, 100);
      }
    );
    const fetchRealTimeParams = () => {
      getRealTimeParams({ project_id: project_id }).then((res) => {
        let params = {};
        res.data.map((item) => {
          let param_name = item.meter_code
            .replace("06750343", "")
            .replace("00fd6cdfb6fb57e7", "")
            .replace("room1", "");
          params[param_name] = item.value;
        });
        console.log("real-time-params", params);
        event.params = params;
        setTimeout(() => {
          chart_init_all();
        }, 500);
      });
    };

    onMounted(() => {
      if (store.state.appConfig.layout.skin === "dark") {
        fontColor.value = "#b8c2cc";
      } else {
        fontColor.value = "#6e6b7b";
      }
      // chart_init_all()
      fetchRealTimeParams();
      interval.value = setInterval(() => {
        fetchRealTimeParams();
      }, 1000 * 60 * 60);
    });
    onUnmounted(() => {
      clearInterval(interval.value);
      event.chart_1.destroy();
      event.chart_1 = null;
      event.chart_2.destroy();
      event.chart_2 = null;
      event.chart_3.destroy();
      event.chart_3 = null;
      event.chart_4.destroy();
      event.chart_4 = null;
      event.chart_5.destroy();
      event.chart_5 = null;
    });

    return {
      ...toRefs(event),
      nanoid,
    };
  },
};
</script>

<style scoped></style>
